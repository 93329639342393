import { InlineWidget } from 'react-calendly';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';

const CalendlyComponent = () => {
  const today = dayjs().format('YYYY-MM-DD');
  const name = 'John Doe';
  const email = 'johndoe@example.com';

  const encodedName = encodeURIComponent(name);
  const encodedEmail = encodeURIComponent(email);


  const url = `https://calendly.com/amnshrma/30min?back=1&month=${today.split('-')[0]}-${today.split('-')[1]}&date=${today}&name=${encodedName}&email=${encodedEmail}`;

  return (
    <Container>
      <h1 id="head">Schedule a Meeting</h1>
      <div className="calendlyr">
          <InlineWidget url={url}   />
      </div>
      
    </Container>
  );
};

export default CalendlyComponent;
