import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Brand from "../assets/media/brand.svg";
function Team() {

 const handleButtonClick = () => {
    const emailAddress = 'rapydlaunch@gmail.com';
    const subject = 'Let\'s Connect | Developement Inquiry';
  
    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoUrl;
  };
  const [isScrolled, setIsScrolled] = useState(false);



  const handleScrollClick = () => {
    const scrollOffset = 500;

    window.scrollTo({
      top: scrollOffset,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
<div className="who">
<div className="rl-child">
            <h4>~Who we are</h4>
            <h2>We'r the top rated agency.</h2>
            <p>Where we are dedicated to delivering high-quality software solutions at an unprecedented pace. Our commitment is to develop innovative and efficient software products within a 45-day timeframe.</p>
            <p>At RapydLaunch, our mission is to revolutionize the software development industry by consistently delivering cutting-edge solutions within 45 days and create a marketplace for all type of software, ensuring customer satisfaction.</p>
        </div>
        <div className="rl-child">
            <div className="trans">
              <img src={Brand} />
            </div>
          
        </div>
        </div>


        <div className="who">
<div className="rl-child-full center">
            <h4>~We don't do traditional development</h4>
            <h2>We are partners, not consultants..</h2>
            <p>We work closely with founders and their teams on every stage of the process, from corporate development to due diligence. </p>
           
            <h2>We build long-term relationships.

</h2>
            <p>With our deep domain expertise and network of more than 1,5000 top decision-makers and influencers, we connect founders with the technology ecosystem to take their companies to the next level.

</p>
           
        </div>
       
        </div>

      <div className="meet-team" id="communty">
        <h2>Meet our rapid team!</h2>
        <div className="testino">
          <a href="https://www.linkedin.com/in/amnshrma/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584577430.png"/>
            </div>
            <div className="test-details">
              <h2>Aman Sharma</h2>
              <p>Founder</p> 
            </div>
            
          </div>
          </a>
          <a href="https://www.linkedin.com/in/abhishek-yadav-b627421a0/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584667668.png"/>
            </div>
            <div className="test-details">
              <h2>Abhishek Yadav</h2>
              <p>Product</p> 
            </div>
          </div>
</a>
    

<a href="https://www.linkedin.com/in/sandeep-shakya-857014249/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1717055990662.png"/>
            </div>
            <div className="test-details">
              <h2>Sandeep Shakya</h2>
              <p>Marketing</p> 
            </div>
          </div>
          </a>
    



<a href="https://www.linkedin.com/in/kshitizdayal/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://media.licdn.com/dms/image/C5603AQFdzJnxF5U1LA/profile-displayphoto-shrink_800_800/0/1616854310833?e=1721865600&v=beta&t=hDCdPDaULyIb2YFxVKaEiVuHCU2l3AOqJnpdqGjsCl0"/>
            </div>
            <div className="test-details">
              <h2>Kshitiz Dayal</h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>
    


<a href="https://www.linkedin.com/in/aayush-shrivastava-60013a17b/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1690284864386.jpeg"/>
            </div>
            <div className="test-details">
              <h2>Aayush Shrivastav</h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>
    


        
    

          <a href="https://www.linkedin.com/in/techyvishwajeet/" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src="https://media.licdn.com/dms/image/D5603AQFBmk8l77EfSg/profile-displayphoto-shrink_800_800/0/1714045558637?e=1723075200&v=beta&t=reo4a5mrZkqMP37FmkT-gLV5n4Laue5o_NpMTH1OQjI" />
            </div>
            <div className="test-details">
              <h2>Vishwajeet</h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>
  
         
          <a href="https://www.linkedin.com/in/sudhanshu-singh-540133229" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1719069405259.jpeg" />
            </div>
            <div className="test-details">
              <h2>Sudhanshu Singh</h2>
              <p>SDE Intern</p> 
            </div>
          </div>
          </a>

          <a href="https://www.linkedin.com/in/deep-fl1507/" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1720436336804.png" />
            </div>
            <div className="test-details">
              <h2>Deependra Kumar</h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>
         
        </div>
        
      </div>






    </Container>
  );
}

export default Team;
